<template>
    <div>
      <b-card title="Carts">
        <b-row class="ml-auto">
          <!-- sorting -->
          <b-col md="4" class="my-1">
            <b-form-group
              label="Sort By"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- filter -->
          <b-col md="4" class="my-1">
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button variant="primary" @click="searchData">
                    Search
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="1" class="my-1">
            <!-- print and save pdf & excel -->
            <div class="ml-auto mr-2">
              <b-dropdown
                style="height: 2.142rem; line-height: 0.5;"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                right
                variant="primary"
                @click="removeExitem"
                size="sm"
                class="dropdown-icon-wrapper"
              >
                <template #button-content
                  ><feather-icon
                    icon="PrinterIcon"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <download-excel :data="exportItems">
                  <b-dropdown-item>
                    <feather-icon icon="DownloadIcon" /> Download
                    Excel</b-dropdown-item
                  >
                </download-excel>
                <b-dropdown-divider />
                <b-dropdown-item @click="genPDF">
                  <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
                >
                <b-dropdown-divider />
              </b-dropdown>
            </div>
          </b-col>
        
        </b-row>
        <b-table
          id="AdminsTable"
          ref="admint"
          striped
          hover
          responsive
          show-empty
          :busy.sync="isBusy"
          class="position-relative"
          :items="items"
            @row-clicked="cartQuickView"
          :fields="fields"
        >
     
        <template #cell(username)="items">
              <router-link :to="{ path: '/User/Show/' + items.item.user_id }" v-if="authPermissions.includes('user')">
                {{ items.item.username }}
              </router-link>
              <p v-else>{{ items.item.username }}</p>
            </template>
          <!-- user actions -->
          <!-- <template #cell(actions)="data">
            <b-button
              style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
              class="btn-icon rounded-circle"
              :to="{ path: '/User/Show/' + data.item.id }"
              variant="success"
            >
              <feather-icon style="width: 18px; height: 18px;" icon="EyeIcon" />
            </b-button>
            <b-button
              style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
              class="btn-icon rounded-circle"
              v-if="authPermissions.includes('update')"
              :to="{ path: '/User/Update/' + data.item.id }"
              variant="warning"
            >
              <feather-icon icon="EditIcon" style="width: 18px; height: 18px;" />
            </b-button>
            <b-button
              style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
              class="btn-icon rounded-circle"
              v-if="authPermissions.includes('delete')"
              @click="deleteAdmin(data.item.id)"
              variant="danger"
            >
              <feather-icon
                icon="Trash2Icon"
                style="width: 18px; height: 18px;"
              />
            </b-button>
          </template> -->
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              @change="fetchData"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination
              @input="fetchData()"
              aria-controls="AdminsTable"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              class="my-0"
            />
          </div>
        </b-card-body>
      </b-card>
      
      
    <!-- Order Details in table for variant -->
    <b-modal
      id="cart-quick-view-modal"
      hide-footer
      ref="modal"
      centered
      title="Cart Details"
      size="lg"
    >
    <b-row v-if="cartModal">
        <b-col md="6">
         
        <p class="text-primary h4 mt-1">
            <b-badge variant="primary">Cart Data</b-badge>
          </p>
          <p class="text-primary h4 mt-1">
            Total: <span class="h5">{{ cartModal.cart_total }}</span>
          </p>
       
          
          <p class="text-primary h4 mt-1" v-if="cartModal.payment_getway_type">
            Payment Provider:
            <b-badge variant="info">{{ cartModal.payment_getway_type }}</b-badge>
          </p>
        
          
        </b-col>
        <b-col md="6">
       
            
          <p class="text-primary h4 mt-1">
            <b-badge variant="secondary">User Data</b-badge>
          </p>
          <p class="text-primary h4 mt-1">
            Username: <span class="h5">{{ cartModal.username }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Email: <span class="h5">{{ cartModal.user_email }}</span>
          </p>
        </b-col>

        <b-col md="6">
       
            
       <p class="text-primary h4 mt-1">
         <b-badge variant="secondary">Vendor Data</b-badge>
       </p>
       <p class="text-primary h4 mt-1">
         name: <span class="h5">{{ cartModal.vendor_name }}</span>
       </p>
     </b-col>
      </b-row>
      
      <b-row v-if="cartModal.variants" class="my-2">
        <b-col md="12">
          <p class="text-secondary h4 mt-1">
            Items
          </p>
          <table class="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">sku</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(variant, index) in cartModal.variants" :key="index">
                <td dir="rtl">{{ variant.product_name }}</td>
                <td>
                  <b-badge :variant="variant.is_new ? 'success' : 'info'">
                    {{ variant.is_new ? 'New' : 'Used' }}
                  </b-badge>
                </td>
                <td style="text-align: center;">{{ variant.sku }}</td>
                <td style="text-align: center;">{{ variant.variant_quantity }}</td>
            
                <td>{{ variant.price }}</td>
                <td>
                  <b-button
                    style="
                      padding: 7px;
                      margin: 6px;
                      width: 35px;
                      height: 35px;
                    "
                    class="btn-icon rounded-circle"
                    :to="{
                      path:
                        (variant.is_new ? '/New' : '/Used') +
                        '/ShowProduct/variant/show/' +
                        variant.id,
                    }"
                    variant="success"
                  >
                    <feather-icon
                      style="width: 18px; height: 18px;"
                      icon="EyeIcon"
                    />
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
   
     
    </b-modal>
      <!-- handle error -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">
          Alert
        </h4>
        <div class="alert-body">
          <ul v-for="(values , index) in errors_back" :key="index">
            <li v-for="(value , indexVal) in values" :key="indexVal">{{value}}</li>
          </ul>
        </div>
      </b-alert>
    </div>
  </template>
  
  <script>
  
  export default {
  
    data() {
      return {
        authPermissions: [],
        showDismissibleAlert: false,
        errors_back: [],
        errors_delete: {},
        perPage: 25,
        pageOptions: [25, 50, 100],
        totalRows: 1,
        isBusy: false,
        currentPage: 1,
        sortBy: 'created_at',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        // table fields
        fields: [
          {
            key: 'id',
            label: 'Cart Id',
            sortable: true,
          },
          {
            key: 'username',
            label: 'Username',
          },
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'user_email',
            label: 'Email',
          },
          
          {
            key: 'user_phone',
            label: 'user Phone',
          },
          {
            key: 'vendor_name',
            label: 'Vendor name',
          },
          {
            key: 'cart_total',
            label: 'Total Price',
          },
          {
            key: 'created_at',
            label: 'Created_At',
            sortable: true,
          },
          {
            key: 'updated_at',
            label: 'Updated_At',
            sortable: true,
          },
        //   'actions',
        ],
        items: [],
        exportItems: [],
        cartModal:{},
      }
    },
    created() {
      this.fetchData()
      this.authPermissions = this.$store.state.authPermissions
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({
            text: f.label,
            value: f.key,
          }))
      },
      rows() {
        return this.totalRows
      },
    },
    watch: {
      sortDesc: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      sortBy: function () {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      filter: function () {
        if (this.filter == '' || this.filter == null) {
          this.fetchData()
        }
      },
    },
    methods: {
      // fetch data of users
      fetchData() {
      this.showDismissibleAlert = false
      // request Brand index API
      var url = null
      if (this.filter == '') {
        url =
          'carts/active/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          'carts/active/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          

          this.totalRows = result.data.data.meta.total
          this.items.length = 0
          this.items = result.data.data.data
          this.exportItems = this.items

          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    
    cartQuickView(item, index, event) {
      this.$bvModal.show('cart-quick-view-modal')
      this.cartModal = item
      //console.log(this.orderModal)
      // //console.log(index)
      // //console.log(event)
    },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      // fetch data of search result
      // fetch data of sort result
       searchData() {
        this.currentPage = 1
        if (this.sortDesc == true) {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
        this.fetchData()
      },
      deleteAdmin(id) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete user.', {
            title: 'Are You Sure?',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .delete('users/' + id + '/delete')
                .then((result) => {
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.fetchData()
                })
                .catch((err) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: 'Error!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  this.errors_back.length = 0
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      })
                    } else {
                      this.errors_back = err.response.data.data
                    }
                    this.showDismissibleAlert = true
                  } else {
                    this.errors_back = []
                    this.errors_back.push({
                      error: 'internal server error',
                    })
                    this.showDismissibleAlert = true
                  }
                })
            }
          })
      },
      removeExitem() {
        for (let ex in this.exportItems) {
          delete this.exportItems[ex].avatar
        }
      },
      genPDF() {
        const { jsPDF } = require("jspdf");
        const doc = new jsPDF()
        let print_body = []
        for (let index = 0; index < this.items.length; index++) {
          print_body[index] = [
            this.items[index].id,
            this.items[index].name,
            this.items[index].email,
            this.items[index].created_at,
            this.items[index].updated_at,
          ]
        }
        doc.autoTable({
          head: [['ID', 'NAME', 'Email', 'CREATED_AT', 'UPDATED_AT']],
          body: print_body,
        })
        doc.save('table.pdf')
      },
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    },
  }
  </script>
  
  